 export const columnInfo = [
   {id: 'sample_identifier', name: 'Sample', section: "Sample", hidden: false, type: 'string', size: 'small-col', description: 'VDJbase sample identifier'},
   {id: 'annotation_path', name: 'Report', section: "Sample", hidden: false, type: 'string', size: 'small-col', description: 'Annotation report'},
   {id: 'subject_identifier', name: 'Subject', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'VDJbase subject identifier'},
   {id: 'name_in_study', name: 'Name in Study', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'Name or identifier of subject used on original study'},
   {id: 'mother_in_study', name: 'Mother in Study', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'Name or identifier of mother in original study'},
   {id: 'father_in_study', name: 'Father in Study', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'Name or identifier of father in original study'},
   {id: 'age', name: 'Age', section: "Subject", hidden: true, type: 'number', size: 'small-col', description: 'Subject age at time of study'},
   {id: 'sex', name: 'Sex', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'Subject sex'},
   {id: 'self_ethnicity', name: 'Declared Ethnicity', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'Self-reported ethnicity'},
   {id: 'grouped_ethnicity', name: 'Grouped Ethnicity', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'Grouped ethnicity'},
   {id: 'population', name: 'Declared Population', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'HapMap Population'},
   {id: 'population_abbr', name: 'Population ID', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'HapMap Population ID'},
   {id: 'super_population', name: 'SuperPopulation ID', section: "Subject", hidden: true, type: 'string', size: 'small-col', description: 'HapMap SuperPopulation ID'},
   {id: 'sample_name_in_study', name: 'Sample Name', section: "Sample", hidden: false, type: 'string', size: 'large-col', description: 'Sample name in study'},
   {id: 'annotation_method', name: 'Annotation Method', section: "Sample", hidden: true, type: 'string', size: 'large-col', description: 'Tool used for annotaiton'},
   {id: 'annotation_format', name: 'Annotation Format', section: "Sample", hidden: true, type: 'number', size: 'small-col', description: 'Subject age at time of study'},
   {id: 'annotation_reference', name: 'Annotation Ref', section: "Sample", hidden: true, type: 'string', size: 'small-col', description: 'Format of annotation report'},
   {id: 'locus_coverage', name: 'IGH Coverage', section: "Sample", hidden: true, type: 'number', size: 'small-col', description: '% locus coverage'},
   {id: 'sequencing_platform', name: 'Sequencing Platform', section: "Sample", hidden: true, type: 'string', size: 'small-col', description: 'Sequencing platform used with this sample'},
   {id: 'assembly_method', name: 'Assembly Method', section: "Sample", hidden: true, type: 'string', size: 'small-col', description: 'Assembly method used with this sample'},
   {id: 'DNA_source', name: 'DNA source', section: "Sample", hidden: true, type: 'string', size: 'small-col', description: 'DNA Source'},
   {id: 'study_id', name: 'Study Id', section: "Study", hidden: true, type: 'string', size: 'small-col', description: 'Accession ID of the study'},
   {id: 'study_name', name: 'Study Name', section: "Study", hidden: true, type: 'string', size: 'small-col', description: 'Name of study in VDJbase'},
   {id: 'study_title', name: 'Study Title', section: "Study", hidden: false, type: 'string', size: 'large-col', description: 'Title of the study as used by the authors'},
   {id: 'study_date', name: 'Study Date', section: "Study", hidden: false, type: 'string', size: 'small-col', description: 'Date of the study'},
   {id: 'study_description', name: 'Study Description', section: "Study", hidden: false, type: 'string', size: 'large-col', description: 'Brief description of the study'},
   {id: 'institute', name: 'Institute', section: "Study", hidden: false, type: 'string', size: 'large-col', description: 'Institution issuing the study'},
   {id: 'researcher', name: 'Researcher', section: "Study", hidden: true, type: 'string', size: 'large-col', description: 'Researcher issuing the study'},
   {id: 'contact', name: 'Contact', section: "Study", hidden: true, type: 'string', size: 'large-col', description: 'Contact name for the study'},
   {id: 'reference', name: 'Reference', section: "Study", hidden: true, type: 'string', size: 'large-col', description: 'Link to reference for the study'},
   {id: 'dataset', name: 'Dataset', section: "Sample", hidden: true, type: 'string', size: 'small-col', description: 'The dataset in which the allele was determined'},
 ];
